import React from 'react';
import { Link } from "gatsby";
import { graphql, useStaticQuery } from "gatsby"

// Shape Images
import Shape1 from "../../assets/images/shape1.png";
import Shape2 from "../../assets/images/shape2.svg";
import Shape3 from "../../assets/images/shape3.svg";
import Shape4 from "../../assets/images/shape4.svg";
import Shape5 from "../../assets/images/shape5.png";

const query = graphql`
  	{
		strapiItStartupBanner {
			title
			shortText
			btnText
			btnUrl
			image {
				id
				name
				url
			}
		}
  	}
`

const MainBanner = () => {

	const data = useStaticQuery(query)
    const {strapiItStartupBanner: {title, shortText, btnText, btnUrl, image}} = data

    return (
		<div className="main-banner">
			<div className="container">
						<div className="row h-100 justify-content-center align-items-center">
						<div className="col-lg-6 offset-lg-1">
								<div className="banner-image">
                                    <img 
                                        src={image.url} 
                                        className="animate__animated animate__fadeInUp animate__delay-0.1" 
                                        alt="Banner Image"
                                    />
								</div>
							</div>
							<div className="col-lg-5">
								<div className="hero-content">
									<h1>{title}</h1>
									<p>{shortText}</p>
									<Link to={btnUrl} className="btn btn-primary">
										{btnText}
									</Link>
								</div>
							</div>
							
						</div>
					</div>
			{/* <div className="d-table">
				<div className="d-table-cell">
					
				</div>
			</div> */}

            {/* Shape Images */}
			<div className="shape1">
				<img src={Shape1} alt="shape"/>
			</div>
			<div className="shape2 rotateme">
				<img src={Shape2} alt="shape"/>
			</div>
			<div className="shape3">
				<img src={Shape3} alt="shape"/>
			</div>
			<div className="shape4">
				<img src={Shape4} alt="shape"/>
			</div>
			<div className="shape5">
				<img src={Shape5} alt="shape"/>
			</div>
			<div className="shape6 rotateme">
				<img src={Shape4} alt="shape"/>
			</div>
			<div className="shape7">
				<img src={Shape4} alt="shape"/>
			</div>
			<div className="shape8 rotateme">
				<img src={Shape2} alt="shape"/>
			</div>
		</div>
    )
}

export default MainBanner